@import "./hero.css";
@import "./navigation.css";
@import "./howItWorks.css";
@import "./usecases.css";
@import "./cookies.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'InstrumentSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./InstrumentSans.woff2) format('woff2');
  }

  html {
    font-family: "InstrumentSans", system-ui, sans-serif;
  }
}

@layer components {
  a {
    @apply text-gray-600 hover:text-blue-600 font-semibold;
 }

  .btn {
    @apply inline-block px-6 py-2 font-semibold text-center bg-blue-500 text-white rounded-full transition duration-150 ease-in-out hover:bg-blue-700 hover:text-blue-100;
  }

  .btn-tall {
    @apply py-2 bg-opacity-0 border border-gray-400;
  }

  .title {
    @apply mb-4 text-3xl font-extrabold text-center;
  }

  .title-small {
    @apply text-2xl font-bold;
  }

  .intro {
    @apply text-xl text-gray-500 text-center mx-8 mb-12 max-w-sm;
  }

  .text {
    @apply text-xl text-gray-500;
  }
}